$(document).ready(function () {

  var scrollTimer = null;
  var $animation_elements = $('.slideanim');
  var $window = $(window);

  function check_if_in_view() {
    scrollTimer = null;
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function () {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top;
      var element_bottom_position = (element_top_position + element_height);

      //check to see if this current container is within viewport
      if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
        $element.addClass('slide');
      }
    });
  }

  $window.on('scroll resize', function () {
    if (scrollTimer) {
      clearTimeout(scrollTimer);   // clear any previous pending timer
    }
    scrollTimer = setTimeout(check_if_in_view, 50);   // set new timer
  });
  $window.trigger('scroll');

  $(document).on('click', "a[href^='#']", function (event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 600, "swing");
  });
});
